import React, { Suspense } from "react";
import { Switch, Route, Redirect } from "react-router-dom";
import Spinner from "react-bootstrap/Spinner";
import ConditionalRoute from "@whitecobalt/tungsten/ConditionalRoute";
import ErrorBoundary from "@whitecobalt/tungsten/ErrorBoundary";
import Loader from "@whitecobalt/tungsten/Loader";
import { useSession, SessionActionType } from "@whitecobalt/tungsten/Session";
import branding from "@config/branding";
import AuthRoutes from "./pages/Auth";
import { ErrorPage } from "./pages/ErrorPage";
import { ContentRoute, Layout } from "@metronic/layout";
import CustomPublic from "./pages/CustomPublic";
import {
	AvailableTags,
    Users,
    RegisteredUsers,
    SentEmails,
    SentSMS,
	SystemSettings,
	Reports,
	Widgets,
	Dashboard,
	Customers,
	MyCompany,
	Invoices,
	PermissionPreset,
	DocumentTemplates,
	EmailTemplates,
	SMSTemplates,
	TimeLogHistory,
	Bills,
	PaymentRuns,
	PaymentAccounts,
	CRMCompanies,
	CRMGroups,
	CRMIntroducers,
	Departments,
	PositionAgreements,
	BusinessProcessDesigner,
	OrganisationalChart,
	Budget,
	EmployeeLeaves,
	AutoEmailCampaigns,
	TimeLogReport,
	SentryDashboard,
	EmailResponses,
	BackgroundProcesses,
	DisconnectedProcesses,
	Wiki,
	EmailInbox,
	CompanyStandards,
	EmailCampaigns,
	DataImports,
	Candidates,
	Roles,
	Contracts,
	ConfirmDebit,
	CMSPages,
	CMSBlogs,
	CMSNavigation,
	CMSFAQs,
	CMSRedirects,
	Projects,
	MyTasks,
	Tasks,
	CustomerInvoices,
	TeamMembers,
	Employees,
	Targets
} from './pages'
import CacheGQL from "@components/CacheGQL";
import { useRouteCorrection } from "@hooks/useRouteCorrection";
import { useHasMFAPrompt } from "@services/store/global";

const MetronicRoute: any = ContentRoute

const Routes = () => {
	const [{ loading, user, others }, dispatch] = useSession();
	const [hasMFAPrompt] = useHasMFAPrompt()
	const hasAuthenthication = !!(
		user &&
		(branding.allowOrganisationSelection === false ||
		(branding.allowOrganisationSelection &&
			others.hasSelectedOrganisation)) &&
		hasMFAPrompt
	)
	const handleRedirect = (redirected: boolean, props: any) => {
		if (redirected) {
			dispatch({
				type: SessionActionType.SET_REDIRECT,
				payload: props.location.pathname
			})
		}
	}

	if(loading) {
		return (
			<Loader active hasSpinner={false} className="kt-splash-screen">
				<div className="mb-4"  data-env-impose="true">
					<img src={branding.logo.splash} style={{maxWidth: 250}}/>
				</div>
				<Spinner animation="border" />
			</Loader>
		)
	}

	return (
		<Switch>
			<Route path="/auth">
				<AuthRoutes />
			</Route>
			<Route path="/unsubscribe">
				<CustomPublic />
			</Route>
			<ConditionalRoute
				condition={hasAuthenthication}
				onRedirect={handleRedirect}
				redirectTo='/auth/login'
				path="/">
				<CacheGQL>
				<Layout>
					<ErrorBoundary>
						<Suspense fallback={(<Loader active />)}>
							<Switch>
								<MetronicRoute path="/cms/pages" component={CMSPages} />
								<MetronicRoute path="/cms/blogs" component={CMSBlogs} />
								<MetronicRoute path="/cms/navigation" component={CMSNavigation} />
								<MetronicRoute path="/cms/redirects" component={CMSRedirects} />
								<MetronicRoute path="/cms/faqs" component={CMSFAQs} />
								<MetronicRoute path="/registered-users" component={RegisteredUsers} />
								<MetronicRoute path="/emails" component={SentEmails} />
								<MetronicRoute path="/sms" component={SentSMS} />
								<MetronicRoute path="/syssettings" component={SystemSettings} />
								<MetronicRoute path="/reports" component={Reports} />
								<MetronicRoute path="/widgets" component={Widgets} />
								<MetronicRoute path="/dashboard" component={Dashboard} />
								<MetronicRoute path="/admin/available-tags" component={AvailableTags} />
								<MetronicRoute path="/admin/users" component={Users} />
								<MetronicRoute path="/admin/invoices" component={Invoices} />
								<MetronicRoute path="/admin/bills" component={Bills} />
								<MetronicRoute path="/admin/permission-presets" component={PermissionPreset} />
								<MetronicRoute path="/admin/document-templates" component={DocumentTemplates} />
								<MetronicRoute path="/admin/email-templates" component={EmailTemplates} />
								<MetronicRoute path="/admin/sms-templates" component={SMSTemplates} />
								<MetronicRoute path="/admin/payment-accounts" component={PaymentAccounts} />
								<MetronicRoute path="/admin/payment-runs" component={PaymentRuns} />
								<MetronicRoute path="/admin/background-process" component={BackgroundProcesses} />
								<MetronicRoute path="/admin/disconnected-jobs" component={DisconnectedProcesses} />
								<MetronicRoute path="/time-logs" component={TimeLogHistory} />
								<MetronicRoute path="/time-log-report" component={TimeLogReport} />
								<MetronicRoute path="/budget/actual" component={Budget} />
								<MetronicRoute path="/budget" component={Budget} />
								<MetronicRoute path="/inbox" component={EmailInbox} />
								<MetronicRoute path="/wiki" component={Wiki} />
								<MetronicRoute path="/data-imports/:metaData1" component={DataImports} />
								{/** Custom Pages - Start */}
									<MetronicRoute path="/confirm-debit" component={ConfirmDebit} />
									<MetronicRoute path="/client-kb" component={Wiki} />
									<MetronicRoute path="/employee-kb" component={Wiki} />
									<MetronicRoute path="/crm/autoemailresponses" component={EmailResponses} />
									<MetronicRoute path="/crm/groups" component={CRMGroups} />
									<MetronicRoute path="/crm/introducers" component={CRMIntroducers} />
									<MetronicRoute path="/crm/companies" component={CRMCompanies} />
									<MetronicRoute path="/crm/autoemailcampaigns" component={AutoEmailCampaigns} />
									<MetronicRoute path="/crm/targets" component={Targets} />
									<MetronicRoute path="/email-campaign" component={EmailCampaigns} />
									<MetronicRoute path="/organisational-chart/:id" component={OrganisationalChart} />
									<MetronicRoute path="/process/diagrams" component={BusinessProcessDesigner} />
									<MetronicRoute path="/process/departments" component={Departments} />
									<MetronicRoute path="/customers" component={Customers} />
									<MetronicRoute path="/suppliers" component={Customers} />
									<MetronicRoute path="/team/position-agreements" component={PositionAgreements} />
									<MetronicRoute path="/team/company-standards" component={CompanyStandards} />
									<MetronicRoute path="/employee-leaves" component={EmployeeLeaves} />
									<MetronicRoute path="/sentry-dashboard" component={SentryDashboard} />
									<MetronicRoute path="/mycompany" component={MyCompany} />
									<MetronicRoute path="/candidates" component={Candidates} />
									<MetronicRoute path="/roles" component={Roles} />
									<MetronicRoute path="/contracts" component={Contracts} />
									<MetronicRoute path="/tasks" component={Tasks} />
									<MetronicRoute path="/my-tasks" component={MyTasks} />
									<MetronicRoute path="/projects" component={Projects} />
									<MetronicRoute path="/invoices" component={CustomerInvoices} />
									<MetronicRoute path="/team/employees" component={Employees} />
									<MetronicRoute path="/team" component={TeamMembers} />
								{/** Custom Pages - End */}
								<Redirect path="/organisational-chart" exact to="/organisational-chart/1" />
								<Redirect path="/" exact to={branding.landingPath} />
								<Route path="*" component={ErrorPage}/>
							</Switch>
						</Suspense>
					</ErrorBoundary>
				</Layout>
				</CacheGQL>
			</ConditionalRoute>
			{/* <Route path="*" component={ErrorPage}/> */}
		</Switch>
	);
}

export default Routes