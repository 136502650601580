export type Prettify<P> = {
  [D in keyof P]: P[D]
} 

export interface UserProps {
  id: number;
  firstname: string;
  surname: string;
  emailAddress: string;
  adminUser: boolean;
  adminOrganisationIDs: number[];
  devUser: boolean;
  disabled: boolean;
  invited: boolean;
  jobTitle: null | string;
  lastLogin: string;
  lockedOut: boolean;
  jobRoleIDs: number[]
  jobRoles: any[];
  loginMFAEnabled: boolean;
  googleAuthenticatorActive: boolean;
  mobileCountry: { 
      value: null | string 
  };
  title: string;
  mobileCountryID: null | number;
  mobileNumber: null | string;
  programIDs: number[];
  permissionIDs: number[];
  permissionPresetID: null | number;
  permissionPreset: { value: null | {
    permissionIDs: number[]
  }}
  phoneNumber: null | string;
  photo: null | string;
  organisationIDs: number[];
  emailSignature: string;
  techAnywhereUser: boolean;
  taskStatusEnabled: boolean;
  organisations: {
    address1: string;
    address2: string;
    address3: string;
    address4: string;
    adminUserIDs: number[];
    companyLogo: null | string;
    companyName: string;
    contactEmailAddress: null | string;
    contactNumber: null | string;
    countryID: number;
    id: number;
    lastNotificationDateTime: null | string;
    organisationTypeID: null | number;
    postcode: string;
    registrationNumber: null | string;
    vatNumber: null | string;
    landingPageDomain: null | string;
    landingPagesEnabled: boolean;
  }[]
  applicationAreaIDs: number[];
  applicationAreas: {
    allowRegistration: boolean;
    baseURL: string;
    confirmEmailTemplate: string;
    description: string;
    enableGoogleAuth: boolean;
    enableMFA: boolean;
    promptMFA: boolean;
    id: number;
    inviteUserTemplate: string;
    maxPasswordAge: null | number;
    mfaTemplate: string;
    passwordMinLength: null | number;
    passwordMinStrength: null | number;
    passwordResetTemplate: string;
    requireEmailConfirmation: boolean;

  }[]
}

export interface OthersProps {
  organisationID?: number;
  hasSelectedOrganisation?: boolean;
}

export enum PermissionAccessType {
  SuperAdminArea = 1,
  SystemSettings = 2,
  ViewUsers = 3,
  EditUser = 4,
  InviteUsers = 5,
  AssignPermissions = 6,
  AddPreset = 7,
  EditPreset = 8,
  ViewPreset = 9,
  Reporting = 108,
  MyCompanyView = 0,
  CRMGroups = 121,
  CRMAccessView = 106,
  CRMAccessEdit = 118,
  CRMAccessDelete = 122,
  CRMBlacklist = 123,
  CRMSetSources = 119,
  AdminSources = 120,
  ApproveBills = 110,
  ApproveInvoices = 111,
  EnvironmentStartStop = 115,
  EnvironmentConfigure = 116,
  EnvironmentUpadateProd = 117,
  ViewWiki = 124,
  EditWiki = 125,
  ViewClientKB = 138,
  ViewEmployeeKB = 139,
  EditClientKB = 140,
  EditEmployeeKB = 141,
  AccessBudget = 113,
  AdminDepts = 104,
  ViewPaymentRun = 112,
  EditCompanyStandards = 128,
  ViewPAandOrgChart = 105,
  EditPA = 126,
  ViewBPD = 101,
  ApproveBPD = 103,
  EditBPD = 102,
  ViewFinanceSection = 109,
  AccessCustomers = 107,
  AccessProject = 149,
  CreateTask = 148,
  AccessTaskAndTimeTracking = 147,
  AccessTimeReporting = 150,
  ClientInvoices = 151,
  ClientTeamContracts = 152,
  SetCRMOwner = 153,
  AccessBankAccount = 154,
}